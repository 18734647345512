<template>
  <div>
    <!-- top -->
    <van-sticky>
      <van-nav-bar @click-left="onClickLeft" title="提现申请" left-arrow>
        <template #right>
          <!-- <p @click="$router.push('/withdrawRecord')">申请纪录</p> -->
        </template>
      </van-nav-bar>
    </van-sticky>
    <!-- top -->
    <div class="wrap">
      <div class="selectWrap">
        <div class="left" :class="{ active: type == 1 }" @click="type = 1">
          <p>余额提现</p>
        </div>
        <div class="right" :class="{ active: type == 2 }" @click="type = 2">
          <p>红利提现</p>
        </div>
      </div>
      <van-form style="width: 90%; margin-top: 16px">
        <van-field
          v-model="amount"
          type="number"
          name="提现金额"
          label="提现金额"
          placeholder="提现金额"
          :rules="[{ required: true, message: '请填写提现金额' }]"
          style="border-radius: 10px 10px 0 0"
        />
        <van-field
          readonly
          clickable
          name="picker"
          :value="value"
          label="提现账户"
          placeholder="选择提现账户"
          @click="showPicker = true"
        />
        <van-field
          v-model="withdraw_password"
          name="提款密码"
          label="提款密码"
          placeholder="提款密码"
          type="password"
          :rules="[{ required: true, message: '请填写提款密码' }]"
        />
        <van-field
          v-model="oneCode"
          name="谷歌验证码"
          label="谷歌验证码"
          placeholder="谷歌验证码"
          v-if="$store.state.selfData.enable_2fa == 1"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="bankList"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup>
        <p class="info" v-if="type != 1">
          红利出款不收手续费,最小提现金额为 ¥1000
        </p>

        <p v-if="type == 1" class="info">
          余额出款收0.5%手续费(未达¥2 已¥2计算)
        </p>
        <div
          style="
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-top: 24px;
          "
        >
          <van-button
            style="
              height: 37px;
              width: 150px;
              border-radius: 100px;
              font-weight: bold;
            "
            color="#4065F0"
            @click="sendWithdraw"
          >
            申请提现
          </van-button>

          <van-button
            color="rgb(205,218,250)"
            style="
              height: 37px;
              width: 150px;
              border-radius: 100px;
              font-weight: bold;
              margin-top: 12px;
            "
            @click="$router.push('/withdrawRecord')"
          >
            <p style="color: #4065f0 !important">申请纪录</p>
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: 1,
      value: "",
      bankList: [],
      showPicker: false,
      amount: "",
      allBank: [],
      last: 0,
      withdraw_password: "",
      oneCode: "",
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push("/");
    },
    sendWithdraw() {
      let bank_acc_id = "";
      let vm = this;
      let sendData = {};
      this.allBank.forEach((e) => {
        if (this.value.indexOf(e.bank_no) != -1) {
          bank_acc_id = e.id;
        }
      });
      if (!this.value || !this.amount) {
        this.$toast.fail("请输入信息");
        return;
      }
      if (this.$store.state.selfData.enable_2fa == 1) {
        sendData = {
          amount: vm.amount,
          type: vm.type,
          bank_acc_id,
          password: this.withdraw_password,
          fee: 1,
          oneCode: this.oneCode,
        };
      } else {
        sendData = {
          amount: vm.amount,
          type: vm.type,
          bank_acc_id,
          password: this.withdraw_password,
          fee: 1,
        };
      }
      this.$http.post(`/mobile/withdrawFee`, sendData).then((res) => {
        if (res.data.code == 0) {
          this.$dialog.confirm({
            title: "提现",
            message: `
              <p style="width:100%;text-align:left;margin:0;padding:0">提现账户: ${
                vm.value
              }</p>
              <p style="width:100%;text-align:left;margin:0;padding:0">提现金额: ${
                res.data.data.amount || vm.amount
              }</p>
              <p style="width:100%;text-align:left;margin:0;padding:0">手续费: ${
                res.data.data.fee || 0
              }</p>`,

            beforeClose(method, done) {
              if (method == "confirm") {
                this.$http
                  .post(`/mobile/withdraw`, {
                    amount: vm.amount,
                    type: vm.type,
                    password: vm.withdraw_password,
                    bank_acc_id,
                    oneCode: vm.oneCode,
                  })
                  .then((res) => {
                    if (res.data.code == 0) {
                      this.$toast.success("成功");
                      vm.withdraw_password = "";
                      vm.amount = "";
                    }
                  });
              }
              done();
            },
          });
        }
      });
    },
    getBank() {
      this.$http.get(`/mobile/bankCard`).then((res) => {
        if (res.data.code == 0) {
          let bank = res.data.data;
          let bankList = [];
          this.bankList = [];
          bank.forEach((e) => {
            bankList.push(`${e.bank_name}-${e.bank_no}`);
          });
          this.bankList = bankList;
          this.allBank = bank;
        }
      });
    },
    getLast() {
      this.$http.get(`/mobile/last`).then((res) => {
        if (res.data.code == 0) {
          this.last = res.data.data;
        }
      });
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
  },
  created() {
    this.getBank();
    this.getLast();
  },
};
</script>
<style lang="scss" scoped>
* {
  // font-size: 14px;
}
// .seletWrap {
//   width: 240px;
//   height: 34px;
//   display: flex;
//   border-radius: 50px;
//   margin: 0 auto;
//   overflow: hidden;
//   background-color: #fff;
//   .left,
//   .right {
//     width: 50%;
//     border-radius: 50px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     p {
//       text-align: center;
//       margin: 0;
//     }
//   }
//   .left.active,
//   .right.active {
//     background: linear-gradient(to right, rgb(255, 96, 52), rgb(238, 10, 36));
//     color: white;
//   }
// }

.selectWrap {
  width: 90%;
  display: flex;
  background-color: #4065f0;
  color: white;
  border-radius: 50px;
  height: 45px;
  margin: 0 auto;
  margin-bottom: 15px;
  div {
    width: 50%;
    // background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    &.active {
      margin: 5px;
      background: white;
      border-radius: 50px;
      color: #4065f0;
      font-weight: bold;
    }
  }
}
p.info {
  background-color: #fff;
  font-size: 12px;
  color: #4065f0;
  text-align: center;
  padding: 10px 0 20px 0;
  font-weight: bold;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}
.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;

  // background-image: url("/home-page_bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  font-size: 14px;
}
.van-cell__title {
  font-size: 14px !important;
}
</style>
